
// ORIGINAL CREDIT: https://github.com/jiridj/hugo-macos-terminal
/* window BEGIN */
.window {
    background: #fff;
    width: 65vw;
    max-width: 600px;
    min-width: 360px;
    margin: auto;
    margin-top: 3.5vh;
    margin-bottom: 3.5vh;
    border: 1px solid #acacac;
    border-radius: 6px;
    filter: drop-shadow(0 0 10px #03edf9);
  }

  .window a {
    text-decoration: none;
  }

  .window span {
    line-height: 9px;
    vertical-align: 50%;
  }

  .titlebar {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0.0, #ebebeb, color-stop(1.0, #d5d5d5)));
    background: -webkit-linear-gradient(top, #ebebeb, #d5d5d5);
    background: -moz-linear-gradient(top, #ebebeb, #d5d5d5);
    background: -ms-linear-gradient(top, #ebebeb, #d5d5d5);
    background: -o-linear-gradient(top, #ebebeb, #d5d5d5);
    background: linear-gradient(top, #ebebeb, #d5d5d5);
    color: #4d494d;
    font-size: 11pt;
    line-height: 22px;
    text-align: center;
    width: 100%;
    height: 22px;
    border-top: 1px solid #f3f1f3;
    border-bottom: 1px solid #b1aeb1;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    cursor: default;
  }

  .buttons {
    padding-left: 8px;
    padding-top: 5px;
    float: left;
    line-height: 0px;
  }

  .buttons:hover a {
    visibility: visible;
  }

  .bclose {
    background: #ff5c5c;
    font-size: 9pt;
    line-height: 11px;
    margin-left: 4px;
    width: 11px;
    height: 11px;
    border: 1px solid #e33e41;
    border-radius: 50%;
    float: left;
    display: inline-block;
  }

  .bclose:active {
    background: #c14645;
    border: 1px solid #b03537;
  }

  .bclose:active .closebutton {
    color: #4e0002;
  }

  .closebutton {
    color: #820005;
    visibility: hidden;
    cursor: default;
  }

  .minimize {
    background: #ffbd4c;
    font-size: 9pt;
    line-height: 11px;
    margin-left: 8px;
    width: 11px;
    height: 11px;
    border: 1px solid #e09e3e;
    border-radius: 50%;
    display: inline-block;
  }

  .minimize:active {
    background: #c08e38;
    border: 1px solid #af7c33;
  }

  .minimize:active .minimizebutton {
    color: #5a2607;
  }

  .minimizebutton {
    color: #9a5518;
    visibility: hidden;
    cursor: default;
  }

  .zoom {
    background: #00ca56;
    font-size: 9pt;
    line-height: 11px;
    margin-left: 4px;
    width: 11px;
    height: 11px;
    border: 1px solid #14ae46;
    border-radius: 50%;
    display: inline-block;
  }

  .zoom:active {
    background: #029740;
    border: 1px solid #128435;
  }

  .zoom:active .zoombutton {
    color: #003107;
  }

  .zoombutton {
    color: #006519;
    visibility: hidden;
    cursor: default;
  }
  /* window END */

  /* terminal BEGIN */
  .terminal {
    margin: 0;
    padding: 5px;
    list-style: none;
    background: $global-background-color-dark;
    font-family: $code-font-family;
    // font-size: 0.6rem;
    line-height: 1.6em;

    -webkit-border-bottom-right-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .terminal ul {
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 0;
  }

  .terminal li {
    width: 100%;
    word-wrap: break-word;
    white-space: pre-wrap;
    position: relative;
    padding: 0 0 0 15px;
    list-style: none;
  }
  /* terminal END */
