// https://github.com/laniehei/goplay  Attribution-NonCommercial 4.0 International

.run {
  background: #2c3e50;
  display: flex;
  justify-content: flex-end;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 7px;

}

.goplay-btn {

  bottom: 7px;
  right: 7px;
  border: 0;
  border-radius: 4px;
  padding: 1px;
  font-size: .75em;
  line-height: 1.8;
  background-color: #777;
  opacity: .6;
  min-width: 55px;
  text-align: center;
}

.goplay-btn:hover {
  opacity: .45;

}

.chroma {
  margin-bottom: 0px;
}
