// .debugprint table, table.debugprint {
//   td, th {
//     @extend .align-top;
//   }
//   .true {
//     @extend .text-success;
//   }

//   .false {
//     @extend .text-danger;
//   }
//   @extend .table;
//   @extend .table-light;
//   @extend .table-bordered;
//   @extend .table-sm;
//   @extend .table-hover;

//   @extend .w-100;
//   @extend .overflow-auto;
// }

/* CSS for Pretty Print for Debug (debugprint.html) */
/* https://github.com/kaushalmodi/hugo-debugprint/blob/master/layouts/partials/debugprint.html */
/* https://github.com/kaushalmodi/hugo-debugprint/blob/master/layouts/partials/debugprint.css */
.debugprintsection {
  // display:flex;
  // flex-wrap:  column;
  margin-top: 300px;

}

.debugprint_wrap {
  overflow: auto;
  max-height: 1100px;
  scrollbar-width: 40px;
  padding: 20px;
  display: inline-block;
  margin: 10px auto;
  border-radius: 5px;
  border: 2px solid #ddd;
  max-width: 75%;
}
.debugprint td,
.debugprint th {
  padding-left: 4px;
  padding-right: 4px;
}
.debugprint th {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.debugprint tr {
  padding: 5px 10px;
}
.debugprint .key {
  font-weight: bold;
}
.debugprint .type {
  /* Hide Type columns in debugprint */
  /* display: none; */
  font-size: 0.9em;
  font-family: monospace;
  font-style: italic;
}
.debugprint .value {
  font-family: monospace;
}
.debugprint .true {
  color: green;
}
.debugprint .false {
  color: red;
}
/* Don't touch the table headers */
.debugprint th.key,
.debugprint th.type,
.debugprint th.value {
  font-family: helvetica, arial, san-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1em;
  font-style: normal;
}