// @mixin details-transition-open {
//   @include transition(max-height 0.8s cubic-bezier(0, 1, 0, 1) -0.1s);
// }

// @mixin details-transition-close {
//   @include transition(max-height 0.8s cubic-bezier(0.5, 0, 1, 0) 0s);
// }


@import "_microtag.scss";
@import "_fonts.scss";
@import "_mailbrew.scss";
@import "_tags.scss";
@import "_terminal.scss";
@import "_microblog.scss";
@import "_buttons.scss";
@import "_webmentions.scss";
@import "single-wide.scss";
@import "_cards.scss";
@import "_debugprint.scss";
@import "_gists.scss";
@import "_loader.scss";
@import "_goplay.scss";
@import "_code_highlighting.scss";
@import "_conversation.scss";
@import "_fontawesome_tweaks.scss";
@import "_sidebar_notes.scss";

$global-font-family: Raleway, Maven Pro, Source Sans Pro, Trebuchet MS, Verdana,
  Verdana Ref, Segoe UI, Candara, Lucida Grande, Lucida Sans Unicode,
  Lucida Sans, Tahoma, sans-serif !default;
$code-font-family: "JetBrainsMono", "Fira Code Retina", "Fira Code", Consolas,
  "Andale Mono WT", "Andale Mono", Menlo, Monaco, "Lucida Console",
  "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono",
  "Liberation Mono", "Nimbus Mono L", "Courier New", Courier,
  "YaHei Consolas Hybrid", monospace, "Segoe UI Emoji", "PingFang SC",
  "Microsoft YaHei" !default;

// Override the whitespace being eliminated
.highlight,
.gist {
  font-family: $code-font-family;

  .table-wrapper {
    white-space: normal;
  }
}

[theme="dark"] {

  .h1-glow,
  .h2-glow,
  .h3-glow,
  .h4-glow,
  .h1,
  .h2,
  .h3,
  .h4,
  .single-title,
  .headerLink,
  .header-title,
  .menu-item:not(#search-desktop) {
    filter: drop-shadow(0 0 10px #03edf9);
  }

  hr {
    filter: drop-shadow(0 0 10px #03edf9);
  }

  .group-title {
    text-align: center;
    filter: drop-shadow(0 0 10px #03edf9);
  }
}

// Set the logo image to be rounded

header .logo,
header .header-title-pre {
  border-radius: 100%;
}

.archive .archive-item-date {
  width: 6em;
}

.fa-search {
  filter: none;
}

.header-title-pre {
  margin-left: 1em;
}

// Prefix My Headers With Nice Markdown styled headers for consistency
.single {
  .content {
    >h2>.header-mark::before {
      content: "#";
    }

    >h3>.header-mark::before {
      content: "##";
    }

    >h4>.header-mark::before {
      content: "###";
    }

    >h5>.header-mark::before {
      content: "####";
    }
  }
}

// cool terminal blinking from pure css
// https://codepen.io/snippy_valson/pen/xVJeQB
#logo-anima {
  @-webkit-keyframes blink {
    to {
      opacity: 0;
    }
  }

  @-moz-keyframes blink {
    to {
      opacity: 0;
    }
  }

  @-ms-keyframes blink {
    to {
      opacity: 0;
    }
  }

  @-o-keyframes blink {
    to {
      opacity: 0;
    }
  }

  @keyframes blink {
    to {
      opacity: 0;
    }
  }

  .arrow {
    font: $code-font-family;
    // color: white;
    text-shadow: 0px 0px 40px #39ff14, 0px 0px 40px #39ff14;
  }

  .dash {
    font: $code-font-family;
    // color: white;
    text-shadow: 0px 0px 40px #39ff14, 0px 0px 40px #39ff14,
      0px 0px 40px #39ff14;
    -webkit-animation-name: blink;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;

    -moz-animation-name: blink;
    -moz-animation-duration: 1s;
    -moz-animation-iteration-count: infinite;

    -ms-animation-name: blink;
    -ms-animation-duration: 1s;
    -ms-animation-iteration-count: infinite;

    -o-animation-name: blink;
    -o-animation-duration: 1s;
    -o-animation-iteration-count: infinite;

    animation-name: blink;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  // body {
  //     background-color: #222222;
  //     background: repeating-linear-gradient(45deg, #2b2b2b 0%, #2b2b2b 10%, #222222 0%, #222222 50%) 0 / 15px 15px;
  // }
  // div {
  //     position: fixed;
  //     top: -50px;
  // }
}

// Contrast on the homepage for summary is too low to be readable, so override with better constrasting font
[theme="dark"] .home[posts] .summary .content {
  color: lighten($global-font-secondary-color-dark, 25%) !important;
}

[theme="dark"] {
  .glow {
    filter: drop-shadow(0 0 10px #03edf9) !important;
  }
}

[theme="light"] {
  .glow {
    filter: none;
  }
}

// Fix header color overlay issues

header {
  .header-wrapper {
    background-color: $header-background-color;

    [theme="dark"] & {
      background-color: $header-background-color-dark;
    }
  }
}

#header-desktop {
  display: block;
  position: fixed;
  height: $header-height + 2;
  line-height: $header-height + 2;
}

// fix content being overlapped from header
.container {
  display: inline;
}

.home {
  .home-profile {
    margin-bottom: 3em;
  }
}

.links {
  display: block;
}