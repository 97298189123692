/* Background */
.bg {
    color: #f8f8f2;
    background-color: #282a36;
}

/* PreWrapper */
.chroma {
    color: #f8f8f2;
    background-color: #282a36;

}

/* Other */
.chroma .x {}

/* Error */
.chroma .err {}

/* CodeLine */
.chroma .cl {}

/* LineTableTD */
.chroma .lntd {
    vertical-align: top;
    padding: 0;
    margin: 0;
    border: 0;
}

/* LineTable */
// .chroma .lntable {
//    max-width:90%;
//     border-spacing: 0;
//     padding: 0;
//     margin: 0;
//     border: 0;
// }
/* LineTable */ .chroma .lntable { border-spacing: 0; padding: 0; margin: 0; border: 0; width: auto; overflow: auto; display: block; }

/* LineHighlight */
.chroma .hl {
    background-color: #ffffcc
}

/* LineNumbersTable */
.chroma .lnt {
    white-space: pre;
    user-select: none;
    margin-right: 0.4em;
    padding: 0 0.4em 0 0.4em;
    color: #7f7f7f
}

/* LineNumbers */
.chroma .ln {
    white-space: pre;
    user-select: none;
    margin-right: 0.4em;
    padding: 0 0.4em 0 0.4em;
    color: #7f7f7f
}

/* Line */
.chroma .line {
    display: flex;
}

/* Keyword */
.chroma .k {
    color: #ff79c6
}

/* KeywordConstant */
.chroma .kc {
    color: #ff79c6
}

/* KeywordDeclaration */
.chroma .kd {
    color: #8be9fd;
    font-style: italic
}

/* KeywordNamespace */
.chroma .kn {
    color: #ff79c6
}

/* KeywordPseudo */
.chroma .kp {
    color: #ff79c6
}

/* KeywordReserved */
.chroma .kr {
    color: #ff79c6
}

/* KeywordType */
.chroma .kt {
    color: #8be9fd
}

/* Name */
.chroma .n {}

/* NameAttribute */
.chroma .na {
    color: #50fa7b
}

/* NameBuiltin */
.chroma .nb {
    color: #8be9fd;
    font-style: italic
}

/* NameBuiltinPseudo */
.chroma .bp {}

/* NameClass */
.chroma .nc {
    color: #50fa7b
}

/* NameConstant */
.chroma .no {}

/* NameDecorator */
.chroma .nd {}

/* NameEntity */
.chroma .ni {}

/* NameException */
.chroma .ne {}

/* NameFunction */
.chroma .nf {
    color: #50fa7b
}

/* NameFunctionMagic */
.chroma .fm {}

/* NameLabel */
.chroma .nl {
    color: #8be9fd;
    font-style: italic
}

/* NameNamespace */
.chroma .nn {}

/* NameOther */
.chroma .nx {}

/* NameProperty */
.chroma .py {}

/* NameTag */
.chroma .nt {
    color: #ff79c6
}

/* NameVariable */
.chroma .nv {
    color: #8be9fd;
    font-style: italic
}

/* NameVariableClass */
.chroma .vc {
    color: #8be9fd;
    font-style: italic
}

/* NameVariableGlobal */
.chroma .vg {
    color: #8be9fd;
    font-style: italic
}

/* NameVariableInstance */
.chroma .vi {
    color: #8be9fd;
    font-style: italic
}

/* NameVariableMagic */
.chroma .vm {}

/* Literal */
.chroma .l {}

/* LiteralDate */
.chroma .ld {}

/* LiteralString */
.chroma .s {
    color: #f1fa8c
}

/* LiteralStringAffix */
.chroma .sa {
    color: #f1fa8c
}

/* LiteralStringBacktick */
.chroma .sb {
    color: #f1fa8c
}

/* LiteralStringChar */
.chroma .sc {
    color: #f1fa8c
}

/* LiteralStringDelimiter */
.chroma .dl {
    color: #f1fa8c
}

/* LiteralStringDoc */
.chroma .sd {
    color: #f1fa8c
}

/* LiteralStringDouble */
.chroma .s2 {
    color: #f1fa8c
}

/* LiteralStringEscape */
.chroma .se {
    color: #f1fa8c
}

/* LiteralStringHeredoc */
.chroma .sh {
    color: #f1fa8c
}

/* LiteralStringInterpol */
.chroma .si {
    color: #f1fa8c
}

/* LiteralStringOther */
.chroma .sx {
    color: #f1fa8c
}

/* LiteralStringRegex */
.chroma .sr {
    color: #f1fa8c
}

/* LiteralStringSingle */
.chroma .s1 {
    color: #f1fa8c
}

/* LiteralStringSymbol */
.chroma .ss {
    color: #f1fa8c
}

/* LiteralNumber */
.chroma .m {
    color: #bd93f9
}

/* LiteralNumberBin */
.chroma .mb {
    color: #bd93f9
}

/* LiteralNumberFloat */
.chroma .mf {
    color: #bd93f9
}

/* LiteralNumberHex */
.chroma .mh {
    color: #bd93f9
}

/* LiteralNumberInteger */
.chroma .mi {
    color: #bd93f9
}

/* LiteralNumberIntegerLong */
.chroma .il {
    color: #bd93f9
}

/* LiteralNumberOct */
.chroma .mo {
    color: #bd93f9
}

/* Operator */
.chroma .o {
    color: #ff79c6
}

/* OperatorWord */
.chroma .ow {
    color: #ff79c6
}

/* Punctuation */
.chroma .p {}

/* Comment */
.chroma .c {
    color: #6272a4
}

/* CommentHashbang */
.chroma .ch {
    color: #6272a4
}

/* CommentMultiline */
.chroma .cm {
    color: #6272a4
}

/* CommentSingle */
.chroma .c1 {
    color: #6272a4
}

/* CommentSpecial */
.chroma .cs {
    color: #6272a4
}

/* CommentPreproc */
.chroma .cp {
    color: #ff79c6
}

/* CommentPreprocFile */
.chroma .cpf {
    color: #ff79c6
}

/* Generic */
.chroma .g {}

/* GenericDeleted */
.chroma .gd {
    color: #ff5555
}

/* GenericEmph */
.chroma .ge {
    text-decoration: underline
}

/* GenericError */
.chroma .gr {}

/* GenericHeading */
.chroma .gh {
    font-weight: bold
}

/* GenericInserted */
.chroma .gi {
    color: #50fa7b;
    font-weight: bold
}

/* GenericOutput */
.chroma .go {
    color: #44475a
}

/* GenericPrompt */
.chroma .gp {}

/* GenericStrong */
.chroma .gs {}

/* GenericSubheading */
.chroma .gu {
    font-weight: bold
}

/* GenericTraceback */
.chroma .gt {}

/* GenericUnderline */
.chroma .gl {
    text-decoration: underline
}

/* TextWhitespace */
.chroma .w {}