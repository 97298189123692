$global-font-family: Raleway, Maven Pro, Source Sans Pro, Trebuchet MS, Verdana, Verdana Ref, Segoe UI, Candara,
    Lucida Grande, Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif !default;
$code-font-family: "JetBrainsMono", "Fira Code Retina", "Fira Code", Consolas, "Andale Mono WT", "Andale Mono", Menlo,
    Monaco, "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono",
    "Liberation Mono", "Nimbus Mono L", "Courier New", Courier, "YaHei Consolas Hybrid", monospace, "Segoe UI Emoji",
    "PingFang SC", "Microsoft YaHei" !default;
$header-height: 1rem;
$global-background-color-dark: #011627;
$header-background-color-dark: #011627;

$global-font-color-dark: #d6deeb;
$table-background-color-dark: #011627;
$table-thead-color-dark: #234d708c;
$MAX_LENGTH: 0;
