// Style Adapted from: https://codepen.io/saiyedfaizan19/pen/EERjyq
// Thank you 🙏  https://codepen.io/saiyedfaizan19

.cards {
  .card-flex-container {
    // display: flex !important;
    // flex-wrap: wrap !important;
    // display: inline-flex !important;
    // flex-wrap: nowrap !important;
    // align-items: flex-start !important;
    // justify-content: center !important;
    display: inline-flex !important;
    flex-wrap: nowrap !important;
    align-items: flex-start !important;
    justify-content: center !important;
    // justify-content: space-between;
    // flex-direction: row;
    // height: 100vh;
  }

  [theme="light"] {
    .card {
    //   color: $global-font-color !important;
          background: $global-border-color !important;
      box-shadow: 0 6px 10px $global-border-color;
      h3 {
        filter: none;
      }
    }
    .card:hover {
      box-shadow: 0 10px 20px $global-border-color;
    }
  }

  [theme="dark"] {
    .card {

    //   color: $global-border-color-dark !important;
      box-shadow: 0 6px 10px $global-border-color-dark;
      h3 {
        filter: drop-shadow(0 0 10px #03edf9);
      }
    }
    .card:hover {
      box-shadow: 0 10px 20px $global-border-color-dark;
    }
  }

  .card {
    border-radius: 4px;
    transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
      0.3s box-shadow,
      0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
    padding: 14px 80px 18px 36px;
    cursor: pointer;
    max-width: 40%;
  }
  .card:hover {
    transform: scale(1.05);
  }

  .card h3 {
    font-weight: 600;
    font-size: 1.5em;
  }

  .card img {
    position: absolute;
    top: 20px;
    right: 15px;
    max-height: 120px;
  }

  .card-1 {
    background-image: url(/images/sheldon-avatar.jpg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 80px;
  }

  .card-2 {
    background-image: url(/images/sheldon-avatar.jpg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 80px;
  }

  .card-3 {
    background-image: url(/images/sheldon-avatar.jpg);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 80px;
  }

  @media (max-width: 990px) {
    .card {
      margin: 20px;
    }
  }
}
