// TODO: improve theme for dark/light, still only dark on the btn-default later properties
[theme="dark"] {
.btn {
    color: $global-font-color-dark !important;
}
.btn:hover,
.btn:focus {
    color: $single-link-hover-color-dark !important;
}
.btn-default {
    color: $global-font-color-dark !important;
    background-color: $header-background-color-dark !important;
    border-color: $global-border-color-dark !important;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active {
    color: $global-font-color-dark !important;
    background-color: $header-background-color-dark !important;
    border-color: $global-border-color-dark !important;
}
}
[theme="light"] {
.btn {
    color: $global-font-color !important;
}
.btn:hover,
.btn:focus {
    color: $single-link-hover-color !important;
}
.btn-default {
    color: $global-font-color !important;
    background-color: $header-background-color !important;
    border-color: $global-border-color !important;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active {
    color: $global-font-color !important;
    background-color: $header-background-color !important;
    border-color: $global-border-color !important;
}
}
$time-long-fade:10;

.btn {
    display: inline-block !important;
    padding: 6px 12px !important;
    margin-bottom: 0 !important;
    font-size: 14px !important;
    font-weight: normal !important;
    line-height: 1.42857143 !important;
    text-align: center !important;
    white-space: nowrap !important;
    vertical-align: middle !important;
    -ms-touch-action: manipulation !important;
        touch-action: manipulation !important;
    cursor: pointer !important;
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
        -ms-user-select: none !important;
            user-select: none !important;
    background-image: none !important;
    border: 1px solid transparent !important;
    border-radius: 4px !important;
    @include transition(all $time_long_fade !important);
}

.btn:focus {
    /*outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;*/
    outline: none !important;
    @include transition(all $time_long_fade !important);
}

.btn:hover,
.btn:focus {
     text-decoration: none !important;
    @include transition(all $time_long_fade !important);
}

.btn-default {
    @include transition(all $time_long_fade !important);
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active {
    @include transition(all $time_long_fade !important);
}

.btn-default:active {
    background-image: none !important;
    @include transition(all $time_long_fade !important);
}
