// _conversation.scss

[theme="dark"] {
  .conversation-card {
    @extend .microblog; // Extends the styles from .microblog
    // Additional styles specific to conversation-card can be added here
  }
}

[theme="light"] {
  .conversation-card {
    @extend .microblog; // Extends the styles from .microblog
    // Additional styles specific to conversation-card can be added here
  }
}

.conversation-card {
  @extend .microblog; // Extends the styles from .microblog
  // Additional styles specific to conversation-card can be added here
  // For example, alternating background for dialogue
  .dialogue {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    &.left {
      background-color: lighten($header-background-color-dark, 40%);
    }
    &.right {
      background-color: lighten($header-background-color-dark, 15%);
      text-align: right;
      padding-left:50%;
    }
  }
}