.page-container {
  display: flex;
  padding: 80px;
}

.special-notes {
  margin-left: 260px;
}
.single-file-directory {
  padding-left: 0;
}
@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

#sidebar-toggle {
  &.spin, &.touch-active {
    animation: spin 0.3s ease-in-out;
  }
}


#sidebar {
  background-color: $global-background-color-dark;
  width: 200px;
  height: calc(100% - 160px);
  position: fixed;
  left: 40px;
  top: 80px;
  overflow: auto;
  padding: 20px;
  transition: transform 0.3s ease-in-out; // Smooth transition for sidebar

  li[data-issection="1"] ul {
    display: none;
  }

  // handle hiding by default on mobile
  @media screen and (max-width: 768px) {
    transform: translateX(-100%);
  }

  // handle showing visible by default on desktop and hiding
  &.visible {
    /* On mobile, show when .visible is added */
    @media screen and (max-width: 768px) {
      transform: translateX(0);
    }

    /* On desktop, hide when .visible is added */
    @media screen and (min-width: 769px) {
      transform: translateX(-100%);
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    ul {
      padding-left: 20px;
    }

    &.not-nested {
      padding-left: 0px;
    }

    li {
      margin-bottom: 10px;
      display: block;


      .single-file-directory {
        // Remove additional indentation for single file directories
        padding-left: 0;

        // Add a page icon before the directory name
        &::before {
          content: '\f15b'; // FontAwesome file icon unicode
          font-family: 'Font Awesome 6 Free';
          padding-right: 0.5em;
        }
      }

      // https://fontawesome.com/v5/docs/web/advanced/css-pseudo-elements
      span, a {
        text-decoration: none;
        &:hover {
          color: inherit;
        }
        &.directory:before, &.direct-page:before {
          font-family: 'Font Awesome 6 Free';
          padding-right: 5px;
        }
        &.directory:before {
          content: '\f07b'; // Font Awesome closed folder icon
          font-family: 'Font Awesome 6 Free';
          font-weight: 900;
          padding-right: 5px;
        }
        &.directory.expanded:before {
          content: '\f07c'; // Font Awesome open folder icon
          font-weight: 400;
          font-family: 'Font Awesome 6 Free';
        }

        &.direct-page:before {
          font-family: 'Font Awesome 6 Free';
          content: '\f15c';
        }
      }
    }

  }
}

@media screen and (max-width: 768px) {
  #sidebar {
    position: absolute;
    left: 0;
    z-index: 1000;
    transform: translateX(-100%);
    width: 50vw;
    height: 100vh;

    &.visible {
      transform: translateX(0);
    }
  }

  // .page-container {
  //   padding: 20px;
  // }

  .special-notes {
    margin-left: 0;
  }
}


// @media screen and (min-width: 769px) {
//   #sidebar {
//     transform: translateX(0); // Always visible on desktop by default

//     &:not(.visible) {
//       transform: translateX(-100%); // Hide when .visible is removed
//     }
//   }
// }

/* Default state for desktop: always visible */
@media screen and (min-width: 769px) {
  #sidebar {
    transform: translateX(0);
  }
}