/* modified from: https://www.inserthtml.com/2012/02/css-tag-icons/*/
.posttag {
    font-family: Helvetica, Arial, sans-serif;
    display: inline-block;
    color: #fff;
    position: relative;
    padding: 10px 10px 10px 10px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    margin: 0 5px 5px 0;
    text-decoration: none;
    -webkit-transition: right 0.2s ease-in;
    -moz-transition: right 0.2s ease-in;
    -ms-transition: right 0.2s ease-in;
    background: #d63838;
}

.posttag:before {
    background: #fff;
    width: 10px;
    height: 10px;
    content: "";
    display: inline-block;
    border-radius: 20px;
    box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.2);
    margin: 0 5px 0 0;
}


.posttag:after {
    content: "";
    border-radius: 5px;
    width: 100%;
    height: 100%;
    box-shadow: inset 0px 5px 40px rgba(255, 255, 255, 0.4), 0px 0px 10px rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    top: 0;
}

.posttag .arrow {
    display: inline-block;
    border: 30px solid;
    border-color: #fff #fff #fff transparent;
    height: 2px;
    z-index: 5000;
    pointer-events: none;
    position: absolute;
    -webkit-transition: right 0.2s ease-in;
    right: -20px;
    top: -12px;
    display: inline-block;
}

.posttag:hover {
    position: relative;
    right: 8px;
}

.posttag:hover .arrow {
    right: -28px;
}

.posttag:hover:after {
    box-shadow: inset 0px -5px 30px rgba(255, 255, 255, 0.4), 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.posttag:active:after {
    box-shadow: inset 0px 0px 30px rgba(0, 0, 0, 0.3), 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.posttag {
    &.draft {
        background-color: #d63838 !important;
    }

    &.scheduled {
        background-color: green !important;
    }
}
