.wide {
    max-width: 90%;
}

.wide-100 {
    max-width: 100%;
}

.list-wide {
    max-width: 85%;
}

.width-85 {
    width: 85%;
}

.wide-list {
    width: 100%;
    max-width:none;

    // max-width: 1200px;
    // height: 90vh;
    // width: 90vw;
    // width: 85%;
    // margin-left: 20%;
    // margin-right: 20%;
    //  display: relative;

}
