code {
  display: inline-block;
  max-width: 100%;
  padding: 0 0.4rem;
  @include overflow-wrap(break-word);
  @include line-break(anywhere);
  font-size: $code-font-size;
  font-family: $code-font-family;
  color: $code-color;

  [theme="dark"] & {
    color: $code-color-dark;
  }
}

pre {
  margin: 0;
  padding: 0.25rem 0 0.25rem 0.5rem;
  @include tab-size(4);

  code {
    padding: 0;
  }

  img {
    min-height: 1em;
    max-height: 1.2em;
    vertical-align: text-bottom;
  }
}

code,
pre,
.highlight table,
.highlight tr,
.highlight td {
  background: $code-background-color !important;

  [theme="dark"] & {
    background: $code-background-color-dark !important;
  }
}

.highlight,
.gist {
  font-family: $code-font-family;
  font-size: $code-font-size;

  .table-wrapper {
    > table,
    > table thead,
    > table tr,
    > table td {
      margin: 0;
      padding: 0;
      border: none !important;
    }
  }
}

.highlight {
  line-height: 1.4em;
  margin: 0.5rem 0;

  > .chroma {
    position: relative;

    .code-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      font-family: $global-font-family;
      font-weight: bold;
      color: $code-info-color;
      background: darken($code-background-color, 8%);

      [theme="dark"] & {
        color: $code-info-color-dark;
        background: darken($code-background-color-dark, 6%);
      }

      &:hover {
        cursor: pointer;
      }

      .code-title {
        width: 100%;
        padding: 0.4rem;
      }

      .code-title::after {
        padding-left: 0.2rem;
        content: "Code";
      }

      @each $type, $text in $code-type-map {
        &.#{$type} .code-title::after {
          content: $text;
        }
      }
    }

    .lntd:first-child {
      min-width: 1.6rem;
      text-align: right;
    }

    .lntd:last-child {
      width: 100%;

      pre {
        @include max-content(min-width);
      }
    }

    .ln {
      padding-right: 0.75rem;
    }

    .hl {
      display: block;
      background-color: darken($code-background-color, 10%);

      [theme="dark"] & {
        background-color: darken($code-background-color-dark, 5%);
      }
    }

    .ln,
    .lnt {
      color: $global-font-secondary-color;

      [theme="dark"] & {
        color: $global-font-secondary-color-dark;
      }
    }

    .arrow {
      padding: 0 0.2rem;
      @include transition(transform 0.2s ease);
    }

    .ellipses {
      padding: 0.4rem;
    }

    .copy {
      display: none;
      padding: 0.4rem;

      &:hover {
        cursor: pointer;
        color: $global-link-hover-color;

        [theme="dark"] & {
          color: $global-link-hover-color-dark;
        }
      }
    }

    .table-wrapper {
      max-height: 0;
      overflow-y: hidden;
      // @include details-transition-open;
    }

    &.open {
      .code-header {
        background: darken($code-background-color, 3%);

        [theme="dark"] & {
          background: darken($code-background-color-dark, 3%);
        }
      }

      .table-wrapper {
        max-height: $MAX_LENGTH;
        // @include details-transition-close;
      }

      .arrow {
        @include transform(rotate(90deg));
      }

      .ellipses {
        display: none;
      }

      .copy {
        display: inline;
      }
    }
  }

  /* Comment */
  .c,
  /* CommentHashbang */  .ch,
  /* CommentMultiline */  .cm,
  /* CommentSingle */  .c1,
  /* CommentSpecial */  .cs,
  /* CommentPreproc */  .cp,
  /* CommentPreprocFile */  .cpf {
    font-style: italic;
  }
  /* GenericUnderline */
  .gl {
    text-decoration: underline;
  }

  @each $class, $color in $code-highlight-color-map {
    .#{$class} {
      color: $color;
    }
  }

  [theme="dark"] & {
    @each $class, $color in $code-highlight-color-map-dark {
      .#{$class} {
        color: $color;
      }
    }
  }
}

.gist {
  .gist-file,
  .gist-data,
  .gist-meta {
    background: none;
  }

  .gist-file {
    border-color: $global-font-secondary-color;

    [theme="dark"] & {
      border-color: $global-font-secondary-color-dark;
    }
  }

  .gist-meta {
    padding: 0.4rem 0.8rem;
    background-color: darken($code-background-color, 5%);

    @include link(false, false);

    [theme="dark"] & {
      background-color: darken($code-background-color-dark, 5%);
    }
  }

  color: $global-font-color;

  [theme="dark"] & {
    color: $global-font-color-dark;

    // imported from https://github.com/lonekorean/gist-syntax-themes/blob/master/stylesheets/one-dark.css
    .highlight {
      background: none;
    }
    .blob-num,
    .blob-code-inner,
    .highlight,
    .pl-enm,
    .pl-ko,
    .pl-mo,
    .pl-mp1 .pl-sf,
    .pl-ms,
    .pl-pdc1,
    .pl-scp,
    .pl-smc,
    .pl-som,
    .pl-va,
    .pl-vpf,
    .pl-vpu,
    .pl-mdr {
      color: #aab1bf;
    }
    .pl-mb,
    .pl-pdb {
      font-weight: 700;
    }
    .pl-c,
    .pl-c span,
    .pl-pdc {
      color: #5b6270;
      font-style: italic;
    }
    .pl-sr .pl-cce {
      color: #56b5c2;
      font-weight: 400;
    }
    .pl-ef,
    .pl-en,
    .pl-enf,
    .pl-eoai,
    .pl-kos,
    .pl-mh .pl-pdh,
    .pl-mr {
      color: #61afef;
    }
    .pl-ens,
    .pl-vi {
      color: #be5046;
    }
    .pl-enti,
    .pl-mai .pl-sf,
    .pl-ml,
    .pl-sf,
    .pl-sr,
    .pl-sr .pl-sra,
    .pl-src,
    .pl-st,
    .pl-vo {
      color: #56b5c2;
    }
    .pl-eoi,
    .pl-mri,
    .pl-pds,
    .pl-pse .pl-s1,
    .pl-s,
    .pl-s1 {
      color: #97c279;
    }
    .pl-k,
    .pl-kolp,
    .pl-mc,
    .pl-pde {
      color: #c578dd;
    }
    .pl-mi,
    .pl-pdi {
      color: #c578dd;
      font-style: italic;
    }
    .pl-mp,
    .pl-stp {
      color: #818896;
    }
    .pl-mdh,
    .pl-mdi,
    .pl-mdr {
      font-weight: 400;
    }
    .pl-mdht,
    .pl-mi1 {
      color: #97c279;
      background: #020;
    }
    .pl-md,
    .pl-mdhf {
      color: #df6b75;
      background: #200;
    }
    .pl-corl {
      color: #df6b75;
      text-decoration: underline;
    }
    .pl-ib {
      background: #df6b75;
    }
    .pl-ii {
      background: #e0c184;
      color: #fff;
    }
    .pl-iu {
      background: #e05151;
    }
    .pl-ms1 {
      color: #aab1bf;
      background: #373b41;
    }
    .pl-c1,
    .pl-cn,
    .pl-e,
    .pl-eoa,
    .pl-eoac,
    .pl-eoac .pl-pde,
    .pl-kou,
    .pl-mm,
    .pl-mp .pl-s3,
    .pl-mq,
    .pl-s3,
    .pl-sok,
    .pl-sv,
    .pl-mb {
      color: #d19965;
    }
    .pl-enc,
    .pl-entc,
    .pl-pse .pl-s2,
    .pl-s2,
    .pl-sc,
    .pl-smp,
    .pl-sr .pl-sre,
    .pl-stj,
    .pl-v,
    .pl-pdb {
      color: #e4bf7a;
    }
    .pl-ent,
    .pl-entl,
    .pl-entm,
    .pl-mh,
    .pl-pdv,
    .pl-smi,
    .pl-sol,
    .pl-mdh,
    .pl-mdi {
      color: #df6b75;
    }
  }
}
.markdown-body {
  background-color: $global-background-color;
  color: $global-font-color;

  .highlight {
    background-color: $code-background-color;
    color: $code-color;

    [theme="dark"] & {
      background-color: $code-background-color-dark;
      color: $code-color-dark;
    }
  }
  [theme="dark"] & {
    background-color: $global-background-color-dark !important;
    color: $global-font-color-dark !important;
  }
}
.markdown-body {
  background-color: $global-background-color;
  color: $global-font-color;

  .highlight {
    background-color: $code-background-color;
    color: $code-color;

    [theme="dark"] & {
      background-color: $code-background-color-dark;
      color: $code-color-dark;
    }
  }
  [theme="dark"] & {
    background-color: $global-background-color-dark !important;
    color: $global-font-color-dark !important;
  }
}
