/* Webmention Section */
/* from https://github.com/maxboeck/eleventy-webmentions/blob/master/css/webmentions.css */

.webmentions {
  border-top: 1px solid #333;
  display: block;
  margin-top: 2em;
  margin-bottom: 0;
  margin-right: auto;
  margin-left: auto;
  width: 80%;
  list-style:none;
}

.no-arrow {
  details>summary {
    list-style: none;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }
}

.webmentions h2,
.webmentions h3,
.webmentions h4 {
  text-align: center;
  filter: drop-shadow(0 0 10px #03edf9);
}

.webmentions__facepile {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.webmentions__face {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  object-fit: cover;
  flex: none;
}

.webmentions__list,
.webmentions__list_facepile {
  list-style-type: none;
  padding: 0;
}

.webmentions__list_facepile {
  margin-top: 0;
}

.webmentions__list_facepile li {
  display: inline;
}

.webmentions__item {
  margin-bottom: 2rem;
}

/* Single Webmention */

.webmention {
  display: block;


}
.inline {
  display: inline;
}
.webmention__meta,
.webmention__author {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.webmention__meta {
  margin-bottom: .5rem;
}

.webmention__author {
  margin-right: .25rem;
}

.webmention__author__photo {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: .5rem;
}

.webmention__pubdate {
  font-style: italic;
}

.p-note {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 1.5em;
  /* avoids phone UI stuff */
}

summary.h4 {
  margin-bottom: 0.25em;
  filter: drop-shadow(0 0 10px #03edf9);
}

@media screen and (min-width: 900px) {
  .webmentions {
    width: 45%;
  }
}

@media screen and (min-width: 1025px) {
  .p-note {
    width: 50%;
    padding-bottom: 0.5em;
  }
}

.u-url {
  color: #333;
  border-bottom: none !important;
}

@media (prefers-color-scheme: dark) {
  .webmentions {
    border-top: 1px solid #fff;
  }

  .p-name {
    color: #fff;
  }

  .u-url {
    color: #ddd;
    /* border-bottom: 1px solid #fff; */
  }
}

/* for imported comments */

.commentOpen {
  margin-top: 1.5em;
  margin-bottom: 0;
}

.commentBody {
  margin-top: 0;
  margin-bottom: 0;
}

.webmention__stats {
  font-size: 1.5em;
  color: $global-font-secondary-color;


  .smaller {
    font-size: 0.7em;
  }

  [theme="dark"] {
    color: $global-font-secondary-color-dark;

    i {
      filter: drop-shadow(0 0 10px #03edf9);
    }
  }
}
[theme="light"] {
  .subtle {
    color: $global-font-secondary-color ;
  }
}
[theme="dark"] {
  .subtle {
    color: $global-font-secondary-color-dark;
  }
}
// MXX.DEV
/* Webmention Section */

// .webmentions {
  //   display: block;
  //   text-align: left;
  // }

  // .webmentions__facepile {
    //   display: flex;
    //   align-items: center;
    //   flex-wrap: wrap;
    // }

    // .webmentions__face {
      //   width: 2rem;
      //   height: 2rem;
      //   border-radius: 50%;
      //   object-fit: cover;
      //   flex: none;
      // }

      // .webmentions__list {
        //   list-style-type: none;
        //   padding: 0;
        // }

        // .webmentions__item {
          //   margin-bottom: 2rem;
          // }

          // /* Single Webmention */

          // .webmention {
            //   display: block;
            // }

            // .webmention__meta,
            // .webmention__author {
              //   display: flex;
              //   align-items: center;
              //   flex-wrap: wrap;
              // }

              // .webmention__meta {
                //   margin-bottom: .5rem;
                // }

                // .webmention__author {
                  //   margin-right: .25rem;
                  // }

                  // .webmention__author__photo {
                    //   height: 3rem;
                    //   width: 3rem;
                    //   border-radius: 50%;
                    //   object-fit: cover;
                    //   margin-right: .5rem;
                    // }

                    // .webmention__pubdate {
                      //   font-style: italic;
                      // }
